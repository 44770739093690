import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { GlobalService } from '../global.service';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-popover2',
  templateUrl: './popover2.page.html',
  styleUrls: ['./popover2.page.scss'],
})
export class Popover2Page implements OnInit {

  ContGen = "156349";
  ContCon = "149872";
  FechaVen = "01/08/2023";
  Alarmas = "505";
  UltimaEscritura = "15/07/2023 09:15";
  FechaVal = "15/07/2023 10:46"
  constructor(popoverController: PopoverController,public global: GlobalService, public loading: LoadingService, public nav: NavController) { }

  ngOnInit() {
  }
  Cerrar() {
    this.global.Popover.dismiss(null, 'Cancelado');
}

Guardar() {
  this.global.Popover.dismiss(null, 'Cargado');
}
}
